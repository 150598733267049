<template>
  <div id="search-block" class="pp-search-block mfp-hide">
    <div class="search">
      <div class="search-btn">
        <button><i class="li_search"></i></button>
      </div>
      <div class="search-input">
        <input type="search" placeholder="Search">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBlock'
};
</script>

<style scoped>

</style>
