<template>
  <header class="header-mb">
    <div class="container">
      <div class="hdm-menu">
        <a class="c-hamburger htx js-mb-menu" href="#mb-menu">
          <span>toggle menu</span>
        </a>
      </div>
      <div class="hdm-logo" v-if="$store.state.about">
        <h1><a href="/"><img :alt="$store.state.about.nameSchool" :src="$store.state.about.media_server_url + '/' + $store.state.about.logo" class="adaptive" style="object-fit: contain;height: 40px; width: 40px"/></a></h1>
      </div>
      <div class="hdm-logo" v-else>
        <h1><a href="/"><img src="https://media.caspian.agency//school_2584/news/images/gerb-dagestanapng-2019-08-22-090043navog.png" class="adaptive" style="object-fit: contain;height: 40px; width: 40px"/></a></h1>
      </div>
      <div class="hdm-search-user">
        <!--        <div class="hd-search">-->
        <!--          <a class="st-btn-1 fa-flip-horizontal js-hd-search" href="#search-block">-->
        <!--            <i class="li_search"></i>-->
        <!--          </a>-->
        <!--          <div class="hd-search-block js-hd-search-block">-->
        <!--            <div class="search">-->
        <!--              <div class="search-input">-->
        <!--                <input placeholder="Type keywords" type="search">-->
        <!--              </div>-->
        <!--              <div class="search-btn">-->
        <!--                <button>Search</button>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="user bvi-open">
          <a href="#"><i class="li_eye"></i></a>
        </div>
        <div class="user">
          <a :href="($store.state.system_data.base_url + '/administrator')"><i class="li_user"></i></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderMobile'
};
</script>

<style scoped>

</style>
