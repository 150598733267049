import moment from 'moment';

export default {
  search_query: '',
  appeal: false,
  easter_egg: false,
  search_page_data: {},
  today: parseInt(moment().format('X') + '000'),
  system_data: {
    protocol: 'https://',
    api: 'sitemo.ru',
    media_server_url: 'https://media.caspian.agency/',
    redirect_url: 'https://siteobr.ru',
    base_url: 'https://ahti.api.sitemo.ru/',
    // base_url: 'http://ltk.api.sitemo.ru',
    // base_url: 'https://akiyurtsoch21.ru',
    // base_url: 'https://s1kvn.api.siteobr.ru/',
    // base_url: 'https://k59mhk.api.siteobr.ru',
    // base_url: 'https://school.caspian.agency',
    key: '$2y$10$ZP3qLjRgukWa0cgDmAtMIO/7z5pxiGFIaAx3IK50ThRZi5T.8Z0ny$2y$10$8EovXTST1jspJ5VIgIUk4.Lybvi1e.w3ChauCo.7mkKZFnr8NUG.6',
    loading: true,
    submitting: false,
    project: '',
    active_class_id: 0,
    active_index_id: 0,
    active_sveden: 0,
    cookies: {},
  }
};
