<template>
  <div class="adding">
    <div class="left_menu">
      <ul class="categories" @click="showBlocks($event)">
        <li :class="'categories_' + item.id" v-for="(item, index) in pages" :key="index">{{item.name}}</li>
        <!--<li class="categories_news">Новости</li>
        <li class="categories_slider">Слайдеры</li>
        <li class="categories_project">Проекты</li>
        <li class="categories_review">Отзывы</li>
        <li class="categories_banner">Баннеры</li>-->
      </ul>
      <div class="blocks" @click="chooseBlock($event)" v-show="showedCategory !== null">
        <ul v-for="(item, index) in pages" :key="index" v-show="showedCategory === item.id">
          <li v-for="(block, i) in item.blocks" :key="i" :data-id="block">
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>{{block}}</span> Блок</p>
          </li>
        </ul>
        <!--<ul ref="news">
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>1</span> Блок с новостями</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>2</span> Блок с новостями</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>3</span> Блок с новостями</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>4</span> Блок с новостями</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>5</span> Блок с новостями</p>
          </li>
        </ul>
        <ul ref="slider">
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>6</span> Слайдер</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>7</span> Слайдер</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>8</span> Слайдер</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>9</span> Слайдер</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>10</span> Слайдер</p>
          </li>
        </ul>
        <ul ref="project">
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>11</span> Проекты</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>12</span> Проекты</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>13</span> Проекты</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>14</span> Проекты</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>15</span> Проекты</p>
          </li>
        </ul>
        <ul ref="review">
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>16</span> Отзывы</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>17</span> Отзывы</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>18</span> Отзывы</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>19</span> Отзывы</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>20</span> Отзывы</p>
          </li>
        </ul>
        <ul ref="banner">
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>21</span> Баннер</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>22</span> Баннер</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>23</span> Баннер</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>24</span> Баннер</p>
          </li>
          <li>
            <img src="https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder.png" alt="">
            <p><span>25</span> Баннер</p>
          </li>
        </ul>-->
      </div>
    </div>
    <div class="overlay" @click="$store.state.addingBlock = false"></div>
  </div>
</template>

<script>
export default {
  name: 'LeftMenu.vue',
  data () {
    return {
      pages: {
        news: {
          id: 0,
          name: 'Новости',
          blocks: [1, 2, 4]
        },
        sliders: {
          id: 1,
          name: 'Слайдер',
          blocks: [3, 5]
        }
      },
      showedCategory: null
    };
  },
  methods: {
    showBlocks (e) {
      if (e.target.tagName === 'LI') {
        let catId = Number(e.target.className.replace('categories_', ''));
        this.showedCategory = catId;
      }
    },
    chooseBlock (e) {
      if (e.target.closest('LI') !== null) {
        let blockId = e.target.closest('LI').dataset.id;
        this.$emit('chooseBlock', blockId);
        this.$store.state.addingBlock = false;
      }
    }
  }
};
</script>

<style lang="scss">
.adding {
  z-index: 3;
}

.left_menu {
  background-color: #fff;
  z-index: 3;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  background-color: rgba(black, 0.3);
}

.categories {
  width: 250px;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  box-shadow: 0 2px 5px 9px rgba(black, 0.1);
  padding-top: 30px;
}

.categories > li {
  line-height: 2.5;
  text-indent: 20px;
  transition: 0.3s;
  font-size: 16px;

  &:hover {
    background-color: #E9E9E9;
    cursor: pointer;
    transition: 0.3s;
  }
}

.blocks {
  width: 350px;
  overflow-y: auto;
  overflow-x: visible;
  box-shadow: 0 2px 5px 9px rgba(black, 0.3);
  height: 100%;

  ul {
    padding-top: 30px;
    /*display: none;*/
  }

  li {
    padding: 5px 0;
    margin-bottom: 10px;

    &:hover {
      background-color: #fd3a13;
      cursor: pointer;
    }
  }

  img {
    width: 90%;
    height: auto;
    margin-left: 5%;
  }

  p {
    text-indent: 5%;

    span {
      margin-right: 10px;
      font-weight: bold;
    }
  }

}

</style>