<template>
  <div class="page-loader">
    <div class="loader">
      <div class="flipper">
        <div class="front"></div>
        <div class="back"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader'
};
</script>
