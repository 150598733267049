import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/home',
    redirect: "/news",
    name: 'index',
    component: () => import('../views/News.vue')
  },
  {
    path: '/appeal',
    name: 'appeal',
    component: () => import('../views/Appeal.vue')
  },
  {
    path: '/rule',
    name: 'rule_main',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/rule/:id',
    name: 'rule',
    component: () => import('../views/RuleDocuments.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News.vue')
  },
  {
    path: '/news/:id',
    name: 'open_news',
    component: () => import('../views/Article.vue')
  },
  {
    path: '/posters',
    name: 'posters',
    component: () => import('../views/Posters.vue')
  },
  {
    path: '/posters/:id',
    name: 'open_poster',
    component: () => import('../views/Poster.vue')
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/projects/:id',
    name: 'open_project',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/Services.vue')
  },
  {
    path: '/services/:id',
    name: 'open_service',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/attractions',
    name: 'attractions',
    component: () => import('../views/Attractions.vue')
  },
  {
    path: '/attractions/:id',
    name: 'openAttractions',
    component: () => import('../views/Photos.vue')
  },
  // {
  //   path: '/service/healthcare',
  //   name: 'healthcare',
  //   component: () => import('../views/Healthcare.vue')
  // },
  {
    path: '/service/healthcare/:id',
    name: 'open_healthcare',
    component: () => import('../views/Project.vue')
  },
  // {
  //   path: '/service/culture',
  //   name: 'culture',
  //   component: () => import('../views/Culture.vue')
  // },
  {
    path: '/service/culture/:id',
    name: 'open_culture',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/service/massmedia',
    name: 'massmedia',
    component: () => import('../views/MassMedia.vue')
  },
  {
    path: '/service/massmedia/:id',
    name: 'open_massmedia',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('../views/Gallery.vue')
  },
  {
    path: '/gallery/:id',
    name: 'open_gallery',
    component: () => import('../views/Photos.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/Contacts.vue')
  },
  {
    path: '/mapregion',
    name: 'mapregion',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/magazine/:id',
    name: 'open_magazine',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/bests',
    name: 'bests',
    component: () => import('../views/BestOfUs.vue')
  },
  {
    path: '/bests/:id',
    name: 'open_bests',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/documents',
    name: 'document',
    component: () => import('../views/Documents.vue')
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('../views/Documents.vue')
  },
  {
    path: '/documentcategory/:catid',
    name: 'open_category',
    component: () => import('../views/CategoryDocument.vue')
  },
  {
    path: '/documents/:catid/:id',
    name: 'open_documents',
    component: () => import('../views/Document.vue')
  },
  {
    path: '/head/',
    name: 'head_page',
    component: () => import('../views/HeadPage.vue')
  },
  {
    path: '*',
    name: 'notfound',
    component: () => import('../views/PageNotFound.vue')
  },
];

routes.forEach((item) => {
  const { meta } = item;
  let { path } = item;
  let baseDir = '/';

  if (meta && meta.project) {
    baseDir = `/${meta.project}`;
  }

  if (path === '/home') {
    path = `${baseDir}/`;
  } else if (path === '/' && item.redirect) {
    item.redirect = `${baseDir}/`;
  } else {
    path = `${path}`;
  }

  item.path = path;
});

export default function createRouter () {
  return new Router({
    mode: 'history',
    scrollBehavior: function (to, from, savedPosition) {
      if (to.hash !== '') {
        return { selector: to.hash };
      } else {
        return { x: 0, y: 0 };
      }
    },
    fallback: false,
    routes,
  });
}
