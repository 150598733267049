import { render, staticRenderFns } from "./LoginBlock.vue?vue&type=template&id=382656fd&scoped=true&"
import script from "./LoginBlock.vue?vue&type=script&lang=js&"
export * from "./LoginBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "382656fd",
  null
  
)

component.options.__file = "LoginBlock.vue"
export default component.exports