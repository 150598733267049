import { render, staticRenderFns } from "./FooterMain.vue?vue&type=template&id=36f20512&scoped=true&"
import script from "./FooterMain.vue?vue&type=script&lang=js&"
export * from "./FooterMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f20512",
  null
  
)

component.options.__file = "FooterMain.vue"
export default component.exports