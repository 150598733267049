export default {
  mainMenu (state, menu) {
    state.main_menu = menu;
  },
  footerMenu (state, menu) {
    state.footer_menu = menu;
  },
  links (state, links) {
    state.links = links;
  },
  projects (state, projects) {
    state.projects = projects;
  },
  project (state, project) {
    state.project = project;
  },
  bests (state, bests) {
    state.bests = bests;
  },
  documents (state, documents) {
    state.documents = documents;
  },
  document (state, param) {
    let catid = param.catid;
    let docid = param.id;

    state.documents.forEach(category => {
      if (category.id === +catid) {
        state.category = category;
      }

      if (category.id === +catid && !!docid) {
        category.get_document_content.forEach(document => {
          if (document.id === +docid) {
            state.document = document;
          }
        });
      }

    });
  },
  news (state, news) {
    state.news = news;
  },
  article (state, article) {
    state.article = article;
  },
  news_cat (state, news_cat) {
    state.news_cat = news_cat;
  },
  services (state, services) {
    state.services = services;
  },
  appealList (state, appeal_list) {
    state.appeal_list = appeal_list;
  },
  appeals (state, appeals) {
    state.appeals = appeals;
  },
  service (state, service) {
    state.service = service;
  },
  posters (state, posters) {
    state.posters = posters;
  },
  poster (state, poster) {
    state.poster = poster;
  },
  magazine (state, magazine) {
    state.magazine = magazine;
  },
  images (state, images) {
    state.images = images;
  },
  gallery (state, gallery) {
    state.gallery = gallery;
  },
  attractions (state, attractions) {
    state.attractions = attractions;
  },
  healthcare (state, healthcare) {
    state.healthcare = healthcare;
  },
  culture (state, culture) {
    state.culture = culture;
  },
  mass_media (state, mass_media) {
    state.mass_media = mass_media;
  },
  about (state, about) {
    state.about = about;
  },
  ruleDocContent (state, rule_doc_content) {
    state.rule_doc_content = rule_doc_content;
  },
  ruleDocDocs (state, rule_doc_docs) {
    state.rule_doc_docs = rule_doc_docs;
  },
  ruleDocHeader (state, navigator) {
    state.rule_doc_header = navigator;
  },
  partners (state, partners) {
    state.partners = partners;
  },
  categories_partner (state, categories_partner) {
    state.categories_partner = categories_partner;
  },
  school (state, school) {
    let socials = {
      vk: null,
      im: null,
      fb: null
    };
    if (school.connect_social !== undefined) {
      school.connect_social.forEach((item) => {
        switch (item.get_social_group.name) {
          case 'vk':
            socials.vk = item.url;
            break;
          case 'im':
            socials.im = item.url;
            break;
          case 'fb':
            socials.fb = item.url;
            break;
          default:
            break;
        }
      });
    }
    state.socials = socials;
    state.school = school;
  }
};
