<template>
  <div class="container">
    <div class="main-nav-wrap">
      <div class="row">
        <nav class="main-nav">
          <!--          <a class="c-hamburger htx js-asd-menu" href="#aside-menu">-->
          <!--            <span>toggle menu</span>-->
          <!--          </a>-->
          <ul class="main-nav-list sf-menu" style="margin-left: 0">
            <li :key="index" v-for="(link, index) in $store.state.main_menu">
              <a href="#" v-if="link.url === null">{{ link.name }}<i class="fa fa-angle-down"></i></a>
              <router-link :to="{name: link.url}" v-else>{{ link.name }}<i class="fa fa-angle-down" v-if="link.child_navigator && link.child_navigator.length"></i></router-link>
              <ul class="sub" v-if="link.child_navigator && link.child_navigator.length">
                <li :key="index" v-for="(sublink, index) in link.child_navigator">
                  <router-link :to="{name: sublink.url,  params: {id: sublink.id}}" v-if="sublink.url === 'rule'">{{ sublink.name}}</router-link>
                  <router-link :to="{name: sublink.url}" v-else>{{ sublink.name}}</router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: 'appeal'}">Интернет приемная</router-link>
            </li>
          </ul>
        </nav>
        <!--        <div class="hd-search">
                  <a class="st-btn-1 fa-flip-horizontal js-hd-search" href="#search-block">
                    <i class="li_search"></i>
                  </a>
                  <div class="hd-search-block js-hd-search-block">
                    <div class="search">
                      <div class="search-input">
                        <input placeholder="Введите ключевые слова" type="search">
                      </div>
                      <div class="search-btn">
                        <button>Поиск</button>
                      </div>
                    </div>
                  </div>
                </div>-->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MenuComponent',
};
</script>

<style scoped>

</style>
