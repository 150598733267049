<template>
  <footer class="main-footer">
    <div class="container">
      <div class="bottom-part">
        <div class="row">
          <div class="ft-logo" v-if="$store.state.about">
            <router-link :to="{name: 'index'}"><img :alt="$store.state.about.nameSchool" :src="$store.state.about.media_server_url  + '/' + $store.state.about.logo" height="105" style="object-fit: contain" width="105"></router-link>
          </div>
          <div class="ft-logo" v-else>
            <router-link :to="{name: 'index'}"><img height="105" :src="$store.state.system_data.base_url + '/img/placeholder.png'" style="object-fit: contain" width="105"></router-link>
          </div>
          <div class="ft-about">
            <article v-if="$store.state.about">
              <h4 class="ft-title-1">{{ $store.state.about.nameSchool }}</h4>
              <div class="p" v-html="$store.state.about.aboutSchool"></div>
            </article>
          </div>
          <div class="ft-social">
            <ul class="ft-social-list pull-right">
              <li class="vk" v-if="$store.state.socials.vk">
                <a :href="$store.state.socials.vk" rel="nofollow" target="_blank"><i class="fa fa-vk"></i></a>
              </li>
              <li class="fb" v-if="$store.state.socials.fb">
                <a :href="$store.state.socials.fb" rel="nofollow" target="_blank"><i class="fa fa-facebook"></i></a>
              </li>
              <li class="in" v-if="$store.state.socials.im">
                <a :href="$store.state.socials.im" rel="nofollow" target="_blank"><i class="fa fa-instagram"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-bar">
      <div class="container">
        <div class="row">
          <div class="three-quarters">
            <div class="ft-menu">
              <ul class="ft-menu-list">
                <li :key="index" v-for="(link, index) in $store.state.footer_menu">
                  <router-link :to="{name: link.url}">{{ link.name }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="one-quarter sm-full">
            <div class="copyright">© Copyright {{new Date().getFullYear()}} by <a href="https://caspianagency.ru/" target="_blank" style="color: #FD3A13"> Caspianagency</a></div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterMain'
};
</script>

<style scoped>

</style>
