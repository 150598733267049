import axios from 'axios';

function setDefaultData (commit, data) {
  commit('about', data.about);
  commit('mainMenu', data.pagination);
  commit('footerMenu', data.footer_menu);
  commit('school', data.school);
}

export default {
  async homePage (store, url = store.state.system_data.base_url + '/api/mo') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('attractions', response.data.attractions);
    store.commit('gallery', response.data.gallery);
    store.commit('links', response.data.links);
    store.commit('projects', response.data.projects);
    store.commit('news', response.data.news);
    store.commit('news_cat', response.data.news_cat);
    store.commit('bests', response.data.bests);
    store.commit('services', response.data.services);
    store.commit('posters', response.data.posters);
    store.commit('magazine', response.data.magazine);
    store.commit('partners', response.data.partners);
    store.commit('categories_partner', response.data.categories_partner);
  },
  async newsListPage (store, url = store.state.system_data.base_url + '/api/monews') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('news', response.data.news);
  },
  async projectsListPage (store, url = store.state.system_data.base_url + '/api/projects') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('projects', response.data.projects);
  },
  async postersListPage (store, url = store.state.system_data.base_url + '/api/posters') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('posters', response.data.posters);
  },
  async servicesListPage (store, url = store.state.system_data.base_url + '/api/services') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('services', response.data.articles);
  },
  async galleryListPage (store, url = store.state.system_data.base_url + '/api/mogallery') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('gallery', response.data.gallery);
  },
  async galleryPage (store, id) {
    const response = await axios.get(store.state.system_data.base_url + '/api/mogallery/' + id);
    setDefaultData(store.commit, response.data);
    store.commit('images', response.data.page);
  },
  async attractionsListPage (store, url = store.state.system_data.base_url + '/api/moattractions') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('attractions', response.data.gallery);
  },
  async healthcareListPage (store, url = store.state.system_data.base_url + '/api/service/healthcare') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('healthcare', response.data.articles);
  },
  async cultureListPage (store, url = store.state.system_data.base_url + '/api/service/culture') {
    const response = await axios.get(url);
    console.log(response.data.articles);
    setDefaultData(store.commit, response.data);
    store.commit('culture', response.data.articles);
  },
  async massMediaListPage (store, url = store.state.system_data.base_url + '/api/massmedia') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('mass_media', response.data.articles);
  },
  async bestsListPage (store, url = store.state.system_data.base_url + '/api/bests') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('bests', response.data.articles);
  },
  async documentsListPage (store, url = store.state.system_data.base_url + '/api/modocuments') {
    const response = await axios.get(url);
    setDefaultData(store.commit, response.data);
    store.commit('documents', response.data.categories_documents);
  },
  async posterPage (store, id) {
    const response = await axios.get(store.state.system_data.base_url + '/api/poster/' + id);
    setDefaultData(store.commit, response.data);
    store.commit('poster', response.data.poster);
  },
  async newsPage (store, id) {
    const response = await axios.get(store.state.system_data.base_url + '/api/monews/' + id);
    setDefaultData(store.commit, response.data);
    store.commit('article', response.data.news);
  },
  async projectPage (store, id) {
    const response = await axios.get(store.state.system_data.base_url + '/api/article/' + id);
    setDefaultData(store.commit, response.data);
    store.commit('project', response.data.page);
  },
  async servicePage (store, id) {
    const response = await axios.get(store.state.system_data.base_url + '/api/article/' + id);
    setDefaultData(store.commit, response.data);
    store.commit('service', response.data.page);
  },
  async contactsPage (store) {
    const response = await axios.get(store.state.system_data.base_url + '/api/mocontact');
    setDefaultData(store.commit, response.data);
  },
  async appealsPage (store) {
    const response = await axios.get(store.state.system_data.base_url + '/api/appeal');
    setDefaultData(store.commit, response.data);
    store.commit('appeals', response.data.appeals);
    store.commit('appealList', response.data.appeal_list);
  },
  async ruleDocuments (store, id) {
    const response = await axios.get(store.state.system_data.base_url + '/api/rule/' + id);
    setDefaultData(store.commit, response.data);
    store.commit('ruleDocContent', response.data.content);
    store.commit('ruleDocDocs', response.data.documents);
    store.commit('ruleDocHeader', response.data.navigator);
  },
  async headPage (store) {
    const response = await axios.get(store.state.system_data.base_url + '/api/mocontact');
    setDefaultData(store.commit, response.data);
  }
};
