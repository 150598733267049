<template>
  <div id="recentpass" class="sp-popup recentpass mfp-hide">
    <div class="btns">
      <a href="#signin" class="js-popups">Registration</a>
      <a href="#login" class="active js-popups">Login</a>
    </div>
    <div class="form">
      <input type="email" placeholder="Enter Your Email Adress">
      <button class="btn-8">Get new password</button>
      <a href="#signin" class="js-popups"><i class="popup-arr-left-ic"></i> Registration</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecentPassBlock'
};
</script>

<style scoped>

</style>
