<template>
  <div id="signin" class="sp-popup signin mfp-hide">
    <div class="btns">
      <a href="#signin" class="active js-popups">Registration</a>
      <a href="#login" class="js-popups">Login</a>
    </div>
    <div class="social">
      <div>Sign in with social account</div>
      <ul class="pp-social-list">
        <li class="tw">
          <a href=""><i class="fa fa-twitter"></i></a></li>
        <li class="fb">
          <a href=""><i class="fa fa-facebook"></i></a></li>
        <li class="gp">
          <a href=""><i class="fa fa-google-plus"></i></a></li>
        <li class="vk">
          <a href=""><i class="fa fa-vk"></i></a></li>
      </ul>
    </div>
    <div class="pp-title"><span>or</span></div>
    <div class="form not-valid">
      <input type="text" placeholder="Username">
      <input type="email" placeholder="Email">
      <input type="password" placeholder="Password">
      <button class="btn-8">Registration</button>
      <span>A password will be send on your post</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignInBlock'
};
</script>

<style scoped>

</style>
