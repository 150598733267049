import Vue from 'vue';
import moment from 'moment';

moment.locale('ru');
Vue.filter('parse_youtube_uid', function (value) {
  let regex = /(\?v=|embed\/)([\w+.-]{11})/;
  return 'https://www.youtube.com/embed/' + value.match(regex)[2];
});

Vue.filter('parse_youtube_uid_2', function (value) {
  let regex = /(\?v=|embed\/)([\w+.-]{11})/;
  if (value.match(regex)) {
    return 'https://img.youtube.com/vi/' + value.match(regex)[2] + '/sddefault.jpg';
  } else {
    return '/public/img/placeholder.png';
  }
});

Vue.filter('mask_phone_gov', function (value) {
  let mask = '0,(0000),00,00,00';
  let phoneAsArr = value.toString().split('');
  let maskAsArr = mask.split(',');
  let newValue = '';
  let curIteration = 0;
  maskAsArr.forEach(function (item) {
    let itemAsArr = item.split('');
    itemAsArr.forEach(function (value) {
      if (value === '(' || value === ')') {
        newValue += value;
      } else {
        newValue += phoneAsArr[curIteration];
        curIteration++;
      }
    });
    newValue += ' ';
  });
  return newValue;
});

Vue.filter('human_date', function (value) {
  return moment(value).format('DD MMM YYYY');
});
Vue.filter('human_date_with_time', function (value) {
  return moment(value).format('DD MMM YYYY HH:mm');
});
Vue.filter('currentTime', function (value) {
  return moment(parseInt(moment().format('X') + '000')).format('DD MMM YYYY');
});

Vue.filter('date_to_month', function (value) {
  let d = new Date(value);
  let month = '';
  switch (d.getMonth()) {
    case 0:
      month = 'Январь';
      break;
    case 1:
      month = 'Февраль';
      break;
    case 2:
      month = 'Март';
      break;
    case 3:
      month = 'Апрель';
      break;
    case 4:
      month = 'Май';
      break;
    case 5:
      month = 'Июнь';
      break;
    case 6:
      month = 'Июль';
      break;
    case 7:
      month = 'Август';
      break;
    case 8:
      month = 'Сентябрь';
      break;
    case 9:
      month = 'Октябрь';
      break;
    case 10:
      month = 'Ноябрь';
      break;
    case 11:
      month = 'Декабрь';
      break;
    default:
      month = d.getMonth() + 1;
      break;
  }
  return month;
});

Vue.filter('week_day', function (value) {
  let weekday = [];
  weekday[0] = 'Воскресенье';
  weekday[1] = 'Понедельник';
  weekday[2] = 'Вторник';
  weekday[3] = 'Среда';
  weekday[4] = 'Четверг';
  weekday[5] = 'Пятница';
  weekday[6] = 'Суббота';
  return weekday[value];
});
