import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import * as filters from 'helpers/filters';
import Utils from 'utils';

// import YmapPlugin from 'vue-yandex-maps';
import Meta from 'vue-meta';
import Cookie from 'vue-cookie';
import Global from 'plugins/global';
import Seo from 'plugins/seo';
import LoadScript from 'plugins/load-script';

import App from './App.vue';

import createStore from './store';
import createRouter from './router';

import YandexShare from '@cookieseater/vue-yandex-share';

const store = createStore();

// Vue.use(YmapPlugin, settings);
Vue.use(Meta);
Vue.use(Cookie);
Vue.use(Global);
Vue.use(Seo);
Vue.use(LoadScript);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.component('yandex-share', YandexShare);

export default function createApp (context) {
  const protocol = store.state.system_data.protocol;
  const api = store.state.system_data.api;
  if (context.host.indexOf('localhost') === -1) {
    if (context.host.indexOf(api) !== -1) { // Если сайт находится на поддомене siteobr.ru
      store.state.system_data.base_url = protocol + context.host.replace(api, 'api.' + api);
    } else {  // Если сайт имеет собственный домен
      store.state.system_data.base_url = protocol + ('api.' + context.host);
    }
  }

  const router = createRouter();
  sync(store, router);
  const app = new Vue({
    router,
    store,
    render: h => h(App),
  });
  Utils.init(app, store);
  return { app, router, store };
}
