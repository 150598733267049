export default {
  install (Vue) {
    Vue.prototype.$seo = function $seo (title, keywords, description, og_title, og_image, og_description) {
      // const list = {
      //   common: [title || '', keywords || '', description || '', og_title || '', og_image || '', og_description || ''],
      // };

      return {
        title: title || '',
        meta: [
          { vmid: 'og:title', name: 'og:title', content: og_title || '' },
          { vmid: 'og:image', name: 'og:image', content: og_image || '' },
          { vmid: 'og:description', name: 'og:description', content: description || '' },
          { vmid: 'keywords', name: 'keywords', content: keywords || '' },
          { vmid: 'description', name: 'description', content: og_description || '' },
        ],
      };
    };
  },
};
