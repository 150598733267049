import { render, staticRenderFns } from "./HeaderSticky.vue?vue&type=template&id=19424a1f&scoped=true&"
import script from "./HeaderSticky.vue?vue&type=script&lang=js&"
export * from "./HeaderSticky.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19424a1f",
  null
  
)

component.options.__file = "HeaderSticky.vue"
export default component.exports