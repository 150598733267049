import { render, staticRenderFns } from "./SearchBlock.vue?vue&type=template&id=514672f5&scoped=true&"
import script from "./SearchBlock.vue?vue&type=script&lang=js&"
export * from "./SearchBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "514672f5",
  null
  
)

component.options.__file = "SearchBlock.vue"
export default component.exports