<template>
  <div data-server-rendered="true" id="app">
    <loader/>
    <div class="main-wrapper">
      <header-mobile/>
      <header-sticky/>
      <header-main/>
      <div class="inner-wrapper" style="padding: 0;">
        <div class="main">
          <transition mode="out-in" name="fade">
            <router-view/>
          </transition>
          <footer-main/>
        </div>
      </div>
    </div>
    <a class="go-top animated js-go-top" href=""><i class="fa fa-angle-up"></i></a>
    <MobileMenuBlock/>
  </div>
</template>

<script>
import HeaderMobile from './components/layouts/HeaderMobile.vue';
import HeaderSticky from './components/layouts/HeaderSticky.vue';
import HeaderMain from './components/layouts/HeaderMain.vue';
// import StickyBar from './components/layouts/StickyBar.vue';
import FooterMain from './components/layouts/FooterMain.vue';
import LoginBlock from './components/popups/LoginBlock.vue';
import SignInBlock from './components/popups/SignInBlock.vue';
import RecentPassBlock from './components/popups/RecentPassBlock.vue';
import MobileMenuBlock from './components/popups/MobileMenuBlock.vue';
import AsideBlock from './components/popups/AsideBlock.vue';
import LeftMenu from './components/layouts/LeftMenu.vue';
import Loader from './components/Loader.vue';
import SearchBlock from './components/popups/SearchBlock.vue';

export default {
  components: {
    SearchBlock,
    Loader,
    LeftMenu,
    AsideBlock,
    MobileMenuBlock,
    RecentPassBlock,
    SignInBlock,
    LoginBlock,
    FooterMain,
    // StickyBar,
    HeaderMain,
    HeaderSticky,
    HeaderMobile
  },
  beforeMount () {
    let _t = this;
    _t.$loadScript('/public/vendor/jquery/dist/jquery.min.js').then(() => {
      _t.$loadScript('/public/js/dist/js/responsivevoice.min.js').then(() => {
        _t.$loadScript('/public/js/dist/js/js.cookie.js').then(() => {
          _t.$loadScript('/public/js/dist/js/bvi.min.js').then(() => {
            _t.$loadScript('/public/js/dist/js/bvi-init.js').then(() => {
              _t.$loadScript('/public/js/jquery-ui.js').then(() => {
                _t.$loadScript('/public/vendor/waypoints/lib/jquery.waypoints.min.js').then(() => {
                  _t.$loadScript('/public/vendor/magnific-popup/dist/jquery.magnific-popup.min.js').then(() => {
                    _t.$loadScript('/public/vendor/imagesloaded/imagesloaded.pkgd.min.js').then(() => {
                      _t.$loadScript('/public/vendor/owl-carousel/owl-carousel/owl.carousel.min.js').then(() => {
                        _t.$loadScript('/public/vendor/slick-carousel/slick/slick.min.js').then(() => {
                          _t.$loadScript('/public/vendor/Swiper/dist/js/swiper.jquery.min.js').then(() => {
                            _t.$loadScript('/public/vendor/sticky-kit/jquery.sticky-kit.min.js').then(() => {
                              _t.$loadScript('/public/js/main.js');
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  },
};
</script>

<style lang="scss">
@import "sass/app";
</style>
