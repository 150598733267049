import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import state from './state';

Vue.use(Vuex);
export default function createStore () {
  return new Vuex.Store({
    state: state,
    actions: actions,
    mutations: mutations
  });
}
