<template>
  <div class="mb-menu mfp-hide" id="mb-menu">
    <div class="container">
      <nav class="mobile-nav">
        <ul class="mobile-nav-list">
          <li :key="index" class="mn-item mfp-close-btn-in" v-for="(link, index) in $store.state.main_menu">
            <router-link :to="{name: link.url}" v-if="link.url !== null">{{ link.name }}</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileMenuBlock'
};
</script>
