<template>
  <div class="sticky-header js-sticky-header">
    <menu-component/>
  </div>
</template>

<script>
import MenuComponent from './MenuComponent.vue';
export default {
  name: 'HeaderSticky',
  components: { MenuComponent }
};
</script>

<style scoped>

</style>
