<template>
  <div id="aside-menu" class="aside-menu mfp-hide">
    <div class="am-container">
      <nav class="am-menu">
        <ul class="am-list">
          <li class="am-item">
            <a href="index.html">news</a>
          </li>
          <li class="am-item">
            <a href="javascript:void(0)">FEATURES</a>
          </li>
          <li class="am-item">
            <a href="category_style_three.html">design</a>
          </li>
          <li class="am-item">
            <a href="category_style_four.html">life style</a>
          </li>
          <li class="am-item">
            <a href="video.html">video</a>
          </li>
          <li class="am-item">
            <a href="reviews_stars_style.html">reviews</a>
          </li>
          <li class="am-item">
            <a href="products.html">shop</a>
          </li>
          <li class="am-item">
            <a href="contact_page_style_1.html">Contacts</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="am-container">
      <div class="twitter-posts">
        <ul class="tp-list">
          <li class="tp-list-item">
            <div class="icon"><i class="fa fa-twitter"></i></div>
            Show your web design in a web browser! Check out new awesome tool <a href="">http://www.symu.co  https://www.symu.co</a> 
          </li>
          <li class="tp-list-item">
            <div class="icon"><i class="fa fa-twitter"></i></div>
            Show your web design in a web browser! Check out new awesome tool <a href="">http://www.symu.co  https://www.symu.co</a> 
          </li>
        </ul>
        <a href="" class="tp-all">View All Tweets</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AsideBlock'
};
</script>

<style scoped>

</style>
