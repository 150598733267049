<template>
  <header class="header-tp-4">
    <div class="top-bar">
      <div class="container">
        <div class="top-bar-inner">
          <div class="row">
            <div class="tb-date">
              <i class="li_calendar"></i>{{ '' | currentTime }}
            </div>
            <nav class="tb-social"></nav>
            <div class="tb-saved-art"></div>
            <div class="tb-nav bvi-open">
              <i class="li_eye"></i>
              <a href="#" target="_blank" style="color: #fff;text-decoration: none;">Версия сайта для слабовидящих</a>
            </div>
            <div class="tb-sing-login">
              <i class="li_user"></i>
              <a :href="($store.state.system_data.base_url + '/administrator')" target="_blank">Вход</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-header">
      <div class="mh-top">
        <div class="container">
          <div class="row flex" style="align-items: center;">
            <div class="mh-logo" style="width: auto;">
              <a href="/" v-if="$store.state.about">
                <img :alt="$store.state.about.nameSchool" :src="$store.state.about.media_server_url  + '/' + $store.state.about.logo" class="adaptive" style="height:50px; width:50px; object-fit: contain"/>
              </a>
              <a href="/" v-else>
                <img src="https://media.caspian.agency//school_2584/news/images/gerb-dagestanapng-2019-08-22-090043navog.png" class="adaptive" style="height:50px; width:50px; object-fit: contain"/>
              </a>
            </div>
            <h1 style="padding: 0 15px;">{{ $store.state.about ? $store.state.about.nameSchool : ''}}</h1>
          </div>
        </div>
      </div>
      <div class="mh-bottom">
        <menu-component/>
      </div>
    </div>
  </header>
</template>

<script>

import MenuComponent from './MenuComponent.vue';

export default {
  name: 'HeaderMain',
  components: { MenuComponent },
};
</script>

<style scoped>

</style>
